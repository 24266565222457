import revive_payload_client_Lzw7BwH9pS from "/var/builds/macopediapwafront/production/releases/141175/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.16.0_jiti@2.4.1__ioredis@5._xpysfawnjtrrpbvmgnva7ghkei/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0CCvOlOYod from "/var/builds/macopediapwafront/production/releases/141175/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.16.0_jiti@2.4.1__ioredis@5._xpysfawnjtrrpbvmgnva7ghkei/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1QGeVJbXK0 from "/var/builds/macopediapwafront/production/releases/141175/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.16.0_jiti@2.4.1__ioredis@5._xpysfawnjtrrpbvmgnva7ghkei/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_hDqeAlKGah from "/var/builds/macopediapwafront/production/releases/141175/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@2.79.2_vite@5.4.11_@types+node@22.10.2_sass@1.77_2r5k25krcvvmyylm7wml2orabi/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_BKeOx6Hl36 from "/var/builds/macopediapwafront/production/releases/141175/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.16.0_jiti@2.4.1__ioredis@5._xpysfawnjtrrpbvmgnva7ghkei/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_SyjTHKq6fs from "/var/builds/macopediapwafront/production/releases/141175/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.16.0_jiti@2.4.1__ioredis@5._xpysfawnjtrrpbvmgnva7ghkei/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_2hHTRsRrQj from "/var/builds/macopediapwafront/production/releases/141175/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.16.0_jiti@2.4.1__ioredis@5._xpysfawnjtrrpbvmgnva7ghkei/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_3SIrovhS2e from "/var/builds/macopediapwafront/production/releases/141175/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.16.0_jiti@2.4.1__ioredis@5._xpysfawnjtrrpbvmgnva7ghkei/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/builds/macopediapwafront/production/releases/141175/macopedia-front/.nuxt/components.plugin.mjs";
import prefetch_client_Q4FWmVpFYk from "/var/builds/macopediapwafront/production/releases/141175/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@9.16.0_jiti@2.4.1__ioredis@5._xpysfawnjtrrpbvmgnva7ghkei/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/var/builds/macopediapwafront/production/releases/141175/macopedia-front/.nuxt/formkitPlugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/var/builds/macopediapwafront/production/releases/141175/macopedia-front/.nuxt/pwa-icons-plugin.ts";
import pwa_client_0uYD8qJFon from "/var/builds/macopediapwafront/production/releases/141175/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_magicast@0.3.5_rollup@2.79.2_vite@5.4.11_@types+node@22.10.2_sass@1.77._l3epeziq3ncp6rrmx4eu7hhaly/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import nuxt_plugin_tfjZfVeUPT from "/var/builds/macopediapwafront/production/releases/141175/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@2.79.2/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_7VKKMnaKCn from "/var/builds/macopediapwafront/production/releases/141175/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.8/node_modules/@t3headless/nuxt-typo3/dist/runtime/plugin.js";
import click_outside_h7lGnWxOxp from "/var/builds/macopediapwafront/production/releases/141175/macopedia-ui/plugins/click-outside.ts";
import gtm_client_pGaoj33AfN from "/var/builds/macopediapwafront/production/releases/141175/macopedia-shared/plugins/gtm.client.ts";
import sentry_client_zg0f4Ya8kn from "/var/builds/macopediapwafront/production/releases/141175/macopedia-shared/plugins/sentry.client.ts";
export default [
  revive_payload_client_Lzw7BwH9pS,
  unhead_0CCvOlOYod,
  router_1QGeVJbXK0,
  _0_siteConfig_hDqeAlKGah,
  payload_client_BKeOx6Hl36,
  navigation_repaint_client_SyjTHKq6fs,
  check_outdated_build_client_2hHTRsRrQj,
  chunk_reload_client_3SIrovhS2e,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Q4FWmVpFYk,
  formkitPlugin_pZqjah0RUG,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_0uYD8qJFon,
  nuxt_plugin_tfjZfVeUPT,
  plugin_7VKKMnaKCn,
  click_outside_h7lGnWxOxp,
  gtm_client_pGaoj33AfN,
  sentry_client_zg0f4Ya8kn
]