import { defineNuxtPlugin } from '#app/nuxt'
import { LazyLogo, LazyLogoSmall, LazyT3Frame, LazyT3HtmlParser, LazyT3Link, LazyT3Renderer, LazyT3CeButton, LazyT3CeCaseStudyBanner, LazyT3CeCategoryBoxes, LazyT3CeContainer2cols, LazyT3CeContainer3cols, LazyT3CeContainerFullWidth, LazyT3CeHeader, LazyT3CeHtml, LazyT3CeImage, LazyT3CeMacopediaCodeSnippets, LazyT3CeNewsPi1, LazyT3CeProductBoxes, LazyT3CeShortcut, LazyT3CeSlider, LazyT3CeText, LazyT3CeTextmedia, LazyT3CeTextpic, LazyT3Bl2, LazyT3BlArchives, LazyMediaFile, LazyMediaAudio, LazyMediaImage, LazyMediaVideo, LazyMediaVimeo, LazyMediaYoutube, LazyT3BackendLayout, LazyT3BlDefault, LazyT3CeBullets, LazyT3CeDefault, LazyT3CeDiv, LazyT3CeMenuAbstract, LazyT3CeMenuPages, LazyT3CeMenuPagesList, LazyT3CeMenuSitemap, LazyT3CeMenuSitemapPages, LazyT3CeMenuSubpages, LazyT3CeTable, LazyT3CeUploads, LazyT3LocaleSwitcher, LazyT3MediaGallery } from '#components'
const lazyGlobalComponents = [
  ["Logo", LazyLogo],
["LogoSmall", LazyLogoSmall],
["T3Frame", LazyT3Frame],
["T3HtmlParser", LazyT3HtmlParser],
["T3Link", LazyT3Link],
["T3Renderer", LazyT3Renderer],
["T3CeButton", LazyT3CeButton],
["T3CeCaseStudyBanner", LazyT3CeCaseStudyBanner],
["T3CeCategoryBoxes", LazyT3CeCategoryBoxes],
["T3CeContainer2cols", LazyT3CeContainer2cols],
["T3CeContainer3cols", LazyT3CeContainer3cols],
["T3CeContainerFullWidth", LazyT3CeContainerFullWidth],
["T3CeHeader", LazyT3CeHeader],
["T3CeHtml", LazyT3CeHtml],
["T3CeImage", LazyT3CeImage],
["T3CeMacopediaCodeSnippets", LazyT3CeMacopediaCodeSnippets],
["T3CeNewsPi1", LazyT3CeNewsPi1],
["T3CeProductBoxes", LazyT3CeProductBoxes],
["T3CeShortcut", LazyT3CeShortcut],
["T3CeSlider", LazyT3CeSlider],
["T3CeText", LazyT3CeText],
["T3CeTextmedia", LazyT3CeTextmedia],
["T3CeTextpic", LazyT3CeTextpic],
["T3Bl2", LazyT3Bl2],
["T3BlArchives", LazyT3BlArchives],
["MediaFile", LazyMediaFile],
["MediaAudio", LazyMediaAudio],
["MediaImage", LazyMediaImage],
["MediaVideo", LazyMediaVideo],
["MediaVimeo", LazyMediaVimeo],
["MediaYoutube", LazyMediaYoutube],
["T3BackendLayout", LazyT3BackendLayout],
["T3BlDefault", LazyT3BlDefault],
["T3CeBullets", LazyT3CeBullets],
["T3CeDefault", LazyT3CeDefault],
["T3CeDiv", LazyT3CeDiv],
["T3CeMenuAbstract", LazyT3CeMenuAbstract],
["T3CeMenuPages", LazyT3CeMenuPages],
["T3CeMenuPagesList", LazyT3CeMenuPagesList],
["T3CeMenuSitemap", LazyT3CeMenuSitemap],
["T3CeMenuSitemapPages", LazyT3CeMenuSitemapPages],
["T3CeMenuSubpages", LazyT3CeMenuSubpages],
["T3CeTable", LazyT3CeTable],
["T3CeUploads", LazyT3CeUploads],
["T3LocaleSwitcher", LazyT3LocaleSwitcher],
["T3MediaGallery", LazyT3MediaGallery],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
